<template>
  <v-card class="pa-0 flex-column d-flex pa-5" style="height: 100%">
    <v-row class="fill-height">
      <v-col cols="12">
        <iframe style="height: 100%; width: 100%" :src="url" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="px-1">
        <v-simple-checkbox
          :ripple="false"
          :value="masterContractFlag"
          @input="changeMasterContractFlag"
        ></v-simple-checkbox>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      masterContractFlag: false,
      url: "https://storage.googleapis.com/nsc-static-assets/CMA%20CGM_NSC%20Digital%20Master%20Agreement%20for%20the%20Purchase%20of%20Container%2042023.pdf",
    };
  },
  methods: {
    changeMasterContractFlag(value) {
      this.masterContractFlag = value;
      this.$emit("changeMasterContractFlag", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
