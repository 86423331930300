var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elevation-5",staticStyle:{"height":"calc(100% - 28px)"}},[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"text-end py-2"},[_c('v-btn',{attrs:{"color":"primary px-12","small":"","light":"","disabled":!_vm.items.length},on:{"click":_vm.exportCustomerData}},[_vm._v(" Export ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-5",attrs:{"cols":!!_vm.infoWinner.auctionItemID.length ? 6 : 12}},[_c('v-data-table',{staticClass:"elevation-5 rounded-0 pa-2 ma-0 tabWinners",attrs:{"dense":"","expand-icon":"mdi-file-tree","light":"","hide-default-footer":"","headers":_vm.tableHeader,"items":_vm.items,"height":"calc( 70vh - 44px)","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{style:({
                  fontWeight: 'bolder',
                  backgroundColor:
                    item.auctionItemID == _vm.infoWinner.auctionItemID
                      ? '#3284f4 !important'
                      : 'white !important',
                  color:
                    item.auctionItemID == _vm.infoWinner.auctionItemID
                      ? 'white'
                      : '',
                })},_vm._l((_vm.header),function({ value }){return _c('td',{key:value},[(value === 'actions')?_c('v-row',{staticClass:"flex-nowrap"},[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"lighten-2 rounded-0",attrs:{"color":"primary","block":"","icon":""},on:{"click":function($event){return _vm.setInfoWinner(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-account-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Set Winner")])])],1)],1):_c('span',[_vm._v(_vm._s(item[value]))])],1)}),0)]}}])})],1),(!!_vm.infoWinner.auctionItemID.length)?_c('v-col',{attrs:{"cols":"6"}},[(_vm.loadingCustomers)?_c('v-row',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card',{attrs:{"light":""}},[_c('v-card-text',[_c('v-row',{staticClass:"pa-2"},[_c('v-autocomplete',{staticClass:"lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24 rounded-md",attrs:{"items":_vm.loserCustomers,"color":"white","dense":"","solo":"","light":"","hide-details":"","item-text":"nameBid","item-value":"organization_id","menu-props":{ light: true },"return-object":""},model:{value:(_vm.infoWinner.CustomerId),callback:function ($$v) {_vm.$set(_vm.infoWinner, "CustomerId", $$v)},expression:"infoWinner.CustomerId"}})],1),_c('v-row',{staticClass:"px-2"},[_c('v-btn',{staticClass:"lighten-2 rounded-md",attrs:{"color":"primary","light":"","width":"50%","disabled":!_vm.infoWinner.CustomerId.toString().length},on:{"click":_vm.sendNewWinner}},[_vm._v(" Set Winner ")])],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('AlertTabWinner',{attrs:{"show":_vm.showAlert,"info":_vm.infoWinner},on:{"closeAlert":_vm.closeAlert}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }