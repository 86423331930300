<template>
  <div class="ma-0 pa-5 elevation-5" style="height: calc(100% - 28px)">
    <Tabs
      :tab="tab"
      :tabValues="tabValues"
      @changeTab="changeTab"
      :classes="`elevation-0  font-weight-bold`"
      :btnclasses="'primary  lighten-2 rounded-t-lg rounded-b-0 font-weight-light white--text font-weight-bold'"
      :classActive="'primary darken-1 white--text'"
    />

    <v-row v-if="tab === 0">
      <v-col sm="12" md="6" lg="6">
        <v-row class="pa-5">
          <v-col cols="12">
            <v-card elevation="0" class="ma-auto" light justify="left">
              <div class="text-h4">
                <b>Information</b>
              </div>

              <v-container class="mt-2">
                <v-row no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Status</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-chip
                      :color="
                        detailsItemId.Status == 'Active'
                          ? 'green'
                          : detailsItemId.Status == 'Expired'
                          ? '#ffd600'
                          : 'red'
                      "
                      text-color="white"
                    >
                      <b>{{
                        detailsItemId.Status == "Active"
                          ? "Active"
                          : detailsItemId.Status == "Expired"
                          ? "Expired"
                          : "Inactive"
                      }}</b>
                    </v-chip>
                  </v-col>
                </v-row>

                <div class="pa-2">
                  <v-divider></v-divider>
                </div>

                <v-row no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Auction Name </v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1">
                      <b>{{ detailsItemId.name }}</b></v-sheet
                    >
                  </v-col>
                </v-row>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> ID</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{ detailsItemId.id }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Created By</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{ detailsItemId.CreatedBy }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>

                <div class="pa-2">
                  <v-divider></v-divider>
                </div>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Created Date</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{ serverToLocalTime(detailsItemId.dateCreated) }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Auction Start Time</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>
                        {{
                          fromISOToUtc(detailsItemId.StartDateTime)
                            .toLocal()
                            .toFormat("yyyy-LL-dd HH:mm:ss")
                        }}</b
                      >
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Auction End Time</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{
                        fromISOToUtc(detailsItemId.EndDateTime)
                          .toLocal()
                          .toFormat("yyyy-LL-dd HH:mm:ss")
                      }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>

                <div class="pa-2">
                  <v-divider></v-divider>
                </div>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Total Lots</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{ detailsItemId.lots }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Lots with bids</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{ detailsItemId.lotsBidded }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>

                <div class="pa-2">
                  <v-divider></v-divider>
                </div>

                <v-row class="" no-gutters>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"> Comments</v-sheet>
                  </v-col>
                  <v-col col="2" class="d-flex">
                    <v-sheet class="pa-1"
                      ><b>{{ detailsItemId.comments }}</b>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-else-if="tab === 1"
      class="px-5 pt-5"
      style="height: calc(100% - 28px)"
    >
      <v-col class="fill-height">
        <DetailsPreviewAuction :items="objPreview" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Card from "@/components/Auctions/Card";
import Tabs from "@/components/General/Tabs.vue";
import DetailsPreviewAuction from "@/components/Auctions/DetailsPreviewAuction.vue";

import { mapActions, mapState } from "vuex";
import { serverToLocalTime, fromISOToUtc } from "@/helpers/mocks/dates.js";

export default {
  components: {
    Card,
    Tabs,
    DetailsPreviewAuction,
  },
  async created() {
    let data = await this.getAuctionInfo({
      id: this.detailsItemId.id,
    });

    this.objPreview.lineItems = data.map((x) => {
      return {
        depot: x.Location,
        city: x.City,
        equipmentSize: x.CntrSize,
        equipmentType: x.CntrType,
        minPrice: x.minPrice,
        qty: x.qty,
        depotName: x.depotName,
        comments: x.comments,
        ContainerNos: x.containers.map((x) => x.ContainerNo),
      };
    });
  },
  computed: {
    ...mapState({
      detailsItemId: (state) => state.moduleAuctions.detailsItemId,
    }),
    auctionName() {
      return `Name: ${this.detailsItemId.id} - ${this.detailsItemId.name}`;
    },
  },
  data() {
    return {
      tab: 0,
      tabValues: [{ title: "Auction Details" }, { title: "Lots Details" }],
      objPreview: { lineItems: [] },
    };
  },
  methods: {
    serverToLocalTime,
    fromISOToUtc,
    ...mapActions({
      getAuctionInfo: "moduleAuctions/getAuctionInfo",
    }),
    changeTab(value) {
      this.tab = value;
    },
  },
};
</script>

<style></style>
