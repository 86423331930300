<template>
  <div class="fill-height">
    <Tabs
      :tab="tab"
      :tabValues="tabValues"
      @changeTab="changeTab"
      :classes="`elevation-0  font-weight-bold`"
      :btnclasses="'primary  lighten-2 rounded-t-lg rounded-b-0 font-weight-light white--text font-weight-bold'"
      :classActive="'primary darken-1 white--text'"
    />

    <v-data-table
      dense
      item-key="id"
      light
      hide-default-footer
      class="elevation-5 rounded-0 pa-0 ma-0"
      height="calc( 70vh - 68px)"
      fixed-header
      :headers="header"
      :items="itemsTable"
      :items-per-page="-1"
    >
    </v-data-table>
  </div>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import Tabs from "@/components/General/Tabs.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Tabs,
  },

  props: ["items"],
  async created() {
    let arr = [];
    this.items.lineItems.map((x) =>
      x.ContainerNos
        ? x.ContainerNos.map((container, index) => (arr += `"${container.EquipmentID}",`))
        : null
    );

    if (arr.length) {
      this.damageDetails = await this.getDamageDetails({
        containers: arr.slice(0, -1),
      });
    }
  },
  computed: {
    header() {
      const headerLots = [
        { text: "Pool", value: "pool" },
        { text: "City", value: "city" },
        { text: "Size", value: "size" },
        { text: "Type", value: "type" },
        { text: "Price", value: "price" },
        { text: "Qty", value: "qty" },
        { text: "Depots", value: "name" },
        { text: "Comments", value: "comments" },
        {
          text: "Access Needed",
          value: "AccessNeeded",
        },
      ];
      const headerBids = [
        { text: "Container", value: "EquipmentID" },
        { text: "Size Type", value: "size" },
        { text: "Year", value: "year" },
        { text: "City", value: "city" },
        { text: "CON LOCATION", value: "depot" },
        { text: "LEASE", value: "lease" },
        { text: "Comments", value: "comments" },
        {
          text: "RF MACHINERY MANUFACTURER",
          value: "RFMachineryMFG",
        },
        {
          text: "RF MACHINERY MODEL",
          value: "RFMachineryModel",
        },
      ];

      return this.tab === 0
        ? generateHeaderData(headerLots)
        : generateHeaderData(headerBids);
    },
    itemsLotsDetails() {
      return this.items.lineItems.map((x) => {
        return {
          pool: x.depot,
          city: x.city,
          size: x.equipmentSize,
          type: x.equipmentType,
          price: x.minPrice,
          qty: x.qty,
          name: x.depotName,
          comments: x.comments,
          AccessNeeded: x.access_requirements,
        };
      });
    },
    itemsTable() {
      return this.tab === 0 ? this.itemsLotsDetails : this.damageDetails;
    },
  },
  data() {
    return {
      tab: 0,
      tabValues: [{ title: "Auction Lots Details" }, { title: "Unit Details" }],
      damageDetails: [],
    };
  },
  methods: {
    ...mapActions({
      getDamageDetails: "moduleAuctions/getDamageDetails",
    }),
    changeTab(value) {
      this.tab = value;
    },
  },
};
</script>

<style></style>
