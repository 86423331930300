<template>
  <v-container class="elevation-5 pa-0 ma-0 text panel-buyer panel" fluid>
    <v-row dense class="justify-end align-center">
      <v-col cols="7" class="mr-auto ml-4">
        <v-text-field
          class="text-center ma-1"
          hide-details
          type="text"
          v-model="searchBuyer"
          @input="changeSearchValue"
          outlined
          dense
          single-line
          clearable
          :prepend-inner-icon="'mdi-magnify'"
        >
          <template v-slot:label> Search </template>
        </v-text-field>
      </v-col>

      <v-col cols="2" class="d-flex mr-5">
        <v-btn color="#20212E" class="lighten-2 elevation-5" @click="add" block>
          <span>Add <v-icon dark>mdi-plus</v-icon></span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return { searchBuyer: "" };
  },
  methods: {
    add() {
      this.$emit("addOrg");
    },
    changeSearchValue() {
      this.$emit("changeSearchValue", this.searchBuyer);
    },
  },
};
</script>

<style scoped></style>
