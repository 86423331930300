<template>
  <div>
    <v-btn
      v-if="status === 'Expired'"
      class="elevation-5 mb-2"
      color="primary"
      outlined
      @click="convertToAnOrder"
    >
      <!-- :disabled="!selected.length" -->
      Convert to orders
    </v-btn>
    <v-data-table
      dense
      expand-icon="mdi-file-tree"
      item-key="auctionItemID"
      light
      hide-default-footer
      show-expand
      :ripple="false"
      class="elevation-5 rounded-0 pa-0 ma-0"
      :show-select="status === 'Expired'"
      v-model="selected"
      :items-per-page="-1"
      :headers="header"
      :items="data"
      @item-expanded="itemExpanded"
      @toggle-select-all="selectAllConvertAuction"
      no-data-text="please wait, lots loading"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="padding: 20px !important">
          <v-row>
            <v-col cols="6" class="pa-2 elevation-0">
              <Tabs
                :tab="tab"
                :tabValues="tabValues"
                @changeTab="changeTab"
                :classes="`elevation-0 gap `"
                :btnclasses="'primary lighten-3 rounded-t-lg rounded-b-0 font-weight-light black--text'"
                :classActive="'primary lighten-1 black--text'"
              />
              <HistoryCustomerTable
                :header="tab === 0 ? HEADER_HISTORY : HEADER_CUSTOMER"
                :items="
                  tab === 0
                    ? historyBids[item.auctionItemID]
                    : customerBids[item.auctionItemID]
                "
                :historyCustomerTableLoadingFlag="historyCustomerTableLoadingFlag"
              />
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn light class="primary elevation-5" @click="refresh(item)">
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="pa-2">
                  <Card
                    title="floor price"
                    :content="Number(item.minPrice).toFixed(2)"
                    icon="mdi-currency-usd"
                    :subtitle="`Auction created at  ${serverToLocalTime(
                      item.date_created
                    )}`"
                  />
                </v-col>
                <v-col cols="6" class="pa-2">
                  <Card
                    v-if="!auctionStarted || status !== 'Active'"
                    title="time left to end"
                    icon="mdi-block-helper"
                    colorIcon="red"
                    bgIcon="transparent"
                    sizeIcon="x-large"
                  >
                    <template v-slot:[`content`]="{}">
                      <span class="text-h5 red--text font-weight-bold">
                        {{
                          auctionStarted
                            ? auctionFinished
                              ? "auction has finished"
                              : "auctions is not active"
                            : "Auction Not Yet Started"
                        }}
                      </span>
                    </template>
                  </Card>

                  <CountDown
                    v-else
                    :EndDateTime="EndDateTime"
                    :expired="auctionFinished"
                  />
                  <!-- @onFinish="refreshItem(item)" -->
                </v-col>
                <v-col cols="6" class="pa-2">
                  <Card
                    title="leading bid"
                    :content="Number(item.MaxAmount).toFixed(2)"
                    :subtitle="`Lot Total: $ ${Number(item.MaxAmount * item.qty).toFixed(
                      2
                    )}`"
                    icon="mdi-currency-usd"
                  />
                </v-col>
                <v-col cols="6" class="pa-2">
                  <Card
                    :title="auctionFinished ? 'winning customer' : 'Leading bidder'"
                    :content="item.MaxBidder"
                    subtitle="Customer"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-slot:[`item.MaxAmount`]="{ item }">
        <span>{{ Number(item.MaxAmount).toFixed(2) }}</span>
      </template>
      <template v-slot:[`item.minPrice`]="{ item }">
        <span>{{ Number(item.minPrice).toFixed(2) }}</span>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <span>{{ Number(item.minPrice * item.qty).toFixed(2) }}</span>
      </template>
      <template v-slot:[`item.containers`]="{ item }">
        <span
          class="d-flex items-center justify-center align-center flex-column flex-wrap"
          v-if="item.containers.length"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                light
                class="elevation-5"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                small
                @click="openModalContainer(item.containers)"
              >
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </template>
            <span class="text-subtitle-1 font-weight-bold">See Containers</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span v-if="item.name">{{ item.name }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom offset-x dense v-if="status">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense class="white pa-0 a">
            <v-list-item
              v-for="{ name, method } in menuData"
              :key="method"
              style="border: 1px solid #3284f4; width: 100%"
              tag="button"
              @click="callMehotd(item, method)"
            >
              <v-list-item-title class="primary--text text-capitalize text-overline">{{
                name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.docNo`]="{ item }">
        <router-link :to="`orders/${item.docNo}`" v-if="item.docNo !== null">
          {{ `${item.docNo}` }}
        </router-link>
      </template>

      <template v-slot:[`item.data-table-select`]="{ isSelected, item }">
        <v-simple-checkbox
          :disabled="item.MaxBidder == null || item.docNo !== null"
          :value="isSelected"
          @input="selectSingleonvertAuction(isSelected, item)"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
    </v-data-table>

    <ModalUpdateLot
      v-if="flagModalComments"
      :show="flagModalComments"
      :item="itemModal"
      @showModal="updateComments"
      @updateComments="updateCommentsData"
      title="Update Comments"
    />
    <ModalDeleteLot
      v-if="flagModalDelete"
      :show="flagModalDelete"
      :item="itemModal"
      @showModal="deleteLot"
      @deleteLot="deleteLotExecution"
      title=""
    />
    <ModalURLOrders
      v-if="flagModalOrders"
      :show="flagModalOrders"
      :items="urlOrders"
      @closeModal="closeModalOrders"
      title="Orders Created"
    />
    <ModalContainers
      v-if="flagModalContainers"
      :show="flagModalContainers"
      :items="containers"
      @closeModal="closeModalContainers"
      :title="`Lot ID #${containers[0].auctionItemID}. Containers`"
    />
  </div>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import Tabs from "@/components/General/Tabs.vue";
import HistoryCustomerTable from "@/components/Auctions/HistoryCustomerTable.vue";
import Card from "@/components/Auctions/Card";
import CountDown from "@/components/Auctions/CountDown";
import ModalUpdateLot from "@/components/Auctions/ModalUpdateLot";
import ModalDeleteLot from "@/components/Auctions/ModalDeleteLot";
import ModalURLOrders from "@/components/Auctions/ModalURLOrders";
import ModalContainers from "@/components/Auctions/ModalContainers";
import {
  serverToLocalTime,
  fromISOToUtc,
  today,
  todayFormat,
} from "@/helpers/mocks/dates.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

const HEADER = [
  {
    text: "Lot ID",
    value: "auctionItemID",
  },
  {
    text: "Lot Name",
    value: "name",
  },
  {
    text: "Country",
    value: "Country",
  },
  { text: "City", value: "City" },
  { text: "Depot Code", value: "Location" },
  { text: "Size", value: "CntrSize" },
  { text: "Type", value: "CntrType" },
  { text: "Condition", value: "conditionDescription" },
  { text: "Qty", value: "qty" },
  { text: "Min Price", value: "minPrice" },
  // { text: "Min Bid total", value: "total" },
  { text: "Comments", value: "comments" },
  { text: "Leading Bid", value: "MaxAmount" },
  { text: "Leading Bidder", value: "MaxBidder" },
  { text: "Containers", value: "containers" },
  { text: "Order #", value: "docNo" },
  { text: "", value: "actions" },
];
const HEADER_HISTORY = [
  { text: "Time", value: "date_created" },
  { text: "Amount", value: "amount" },
  { text: "Customer", value: "organization_descr" },
];
const HEADER_CUSTOMER = [
  { text: "Customer", value: "organization_descr" },
  { text: "Highest bid", value: "amount" },
  { text: "Time", value: "date_created" },
];

export default {
  props: [
    "data",
    "EndDateTime",
    "StartDate",
    "status",
    "optionsPagination",
    "auctionFinished",
  ],
  components: {
    Tabs,
    HistoryCustomerTable,
    Card,
    CountDown,
    ModalUpdateLot,
    ModalDeleteLot,
    ModalURLOrders,
    ModalContainers,
  },
  created() {
    // .then((x) => {
    //   this.$emit("setExpandedData", { id: x[0].auctionId, data: x });
    // });

    if (this.status === "Active" && !this.auctionFinished) {
      this.Timer = window.setInterval(() => {
        this.getLineItemsInTheBackground({ data: this.data, time: this.time }).then(
          () => {
            this.data.forEach((x) => {
              const auctionItemID = x.auctionItemID;

              if (
                this.historyBids[auctionItemID] &&
                this.historyBids[auctionItemID].length
              ) {
                const MaxAmount = this.historyBids[auctionItemID][0].amount;
                const MaxBidder = this.historyBids[auctionItemID][0].organization_descr;

                x.MaxAmount = MaxAmount;
                x.MaxBidder = MaxBidder;
              }
            });
          }
        );
      }, this.time * 1000);
    }
  },

  computed: {
    ...mapState({
      // customerBids: (state) => state.moduleAuctions.customerBids,
      historyBids: (state) => state.moduleAuctions.historyBids,
      page: (state) => state.moduleAuctions.page,
      listAuctions: (state) => state.moduleAuctions.listAuctions,
    }),

    ...mapGetters({
      customerBids: "moduleAuctions/customerBids",
    }),
    header() {
      return generateHeaderData(HEADER);
    },
    menuData() {
      return [
        { name: "Update Comments", method: "updateComments" },
        { name: "Delete lot", method: "deleteLot" },
      ];
    },
    auctionStarted() {
      let todayValue = todayFormat();

      return this.StartDate ? this.StartDate < todayValue : false;
    },
    location() {
      return window.location.origin;
    },
  },
  data() {
    return {
      HEADER_HISTORY,
      HEADER_CUSTOMER,
      tab: 0,
      tabValues: [
        {
          title: "History",
        },

        {
          title: "Customer",
        },
      ],
      flagModalComments: false,
      flagModalDelete: false,
      flagModalOrders: false,
      itemModal: null,
      selected: [],
      urlOrders: null,
      historyCustomerTableLoadingFlag: false,
      flagModalContainers: false,
      containers: null,
      Timer: null,
      time: 5, //seconds
    };
  },
  watch: {
    data: {
      handler() {
        if (this.data) this.getLineItemsInTheBackground({ data: this.data });
      },
      immediate: true,
    },
    auctionFinished: {
      async handler() {
        if (this.auctionFinished) {
          clearInterval(this.Timer);
          this.Timer = null;
          if (this.status !== "Expired") {
            let values = await Promise.all([
              this.getAuctionInfoBackground({ id: this.data[0].auctionId }),

              ...this.data.flatMap((item) => {
                return [this.getBids(item)];
              }),
            ]);
            let data = values[0];
            this.$emit("setExpandedData", { id: data[0].auctionId, data });
            console.log(this.data);
          }
        }
      },
    },
  },
  methods: {
    serverToLocalTime,
    ...mapMutations(["setSpinnerShow"]),
    ...mapActions({
      getBids: "moduleAuctions/getBids",
      getBidsAll: "moduleAuctions/getBidsAll",
      getAuctionInfo: "moduleAuctions/getAuctionInfo",
      getAuctionInfoBackground: "moduleAuctions/getAuctionInfoBackground",
      getAuctions: "moduleAuctions/getAuctions",
      updateLotComments: "moduleAuctions/updateLotComments",
      deleteLotItem: "moduleAuctions/deleteLot",
      convertAuction: "moduleAuctions/convertAuction",
      getLineItemsInTheBackground: "moduleAuctions/getLineItemsInTheBackground",
    }),
    fromISOToUtc,
    async convertToAnOrder() {
      await this.getLineItemsInTheBackground({ data: this.data });
      const res = await this.convertAuction(this.selected);

      if (res) {
        this.urlOrders = res.map((x) => x.data);

        this.flagModalOrders = true;
        let data = await this.getAuctionInfo({
          id: this.selected[0].auctionId,
        });
        this.$emit("setExpandedData", { id: this.selected[0].auctionId, data });
        this.selected = [];
      }
    },
    itemExpanded({ item, value }) {
      if (value) {
        this.historyCustomerTableLoadingFlag = true;
        this.getLineItemsInTheBackground({ data: this.data });
        // .then((x) => {
        //   this.$emit("setExpandedData", { id: x[0].auctionId, data: x });
        // });

        this.historyCustomerTableLoadingFlag = false;
      } else {
        clearInterval(this.Timer);
        this.Timer = null;
      }
    },
    changeTab(value) {
      this.tab = value;
    },
    async refresh(item) {
      this.getBids(item);
      let data = await this.getAuctionInfo({ id: item.auctionId });
      this.$emit("setExpandedData", { id: item.auctionId, data });
    },

    callMehotd(item, name) {
      this[name](item);
    },
    updateComments(value) {
      this.itemModal = value ? value : null;
      this.showUpdateModal(value);
    },
    deleteLot(value) {
      this.itemModal = value ? value : null;
      this.showDeleteModal(value);
    },
    showUpdateModal(flag) {
      this.flagModalComments = !!flag;
    },
    showDeleteModal(flag) {
      this.flagModalDelete = !!flag;
    },
    async updateCommentsData(item) {
      let res = await this.updateLotComments({
        data: {
          comments: item.comments,
          auctionid: this.itemModal.auctionId,
          auctionitemid: this.itemModal.auctionItemID,
        },
      });

      if (res) {
        let data = await this.getAuctionInfo({ id: this.itemModal.auctionId });
        this.$emit("setExpandedData", { id: this.itemModal.auctionId, data });
        this.updateComments();
      }
    },
    async deleteLotExecution(item) {
      console.log(this.itemModal.auctionItemID);
      let res = await this.deleteLotItem(this.itemModal.auctionItemID);

      if (res) {
        let data = await this.getAuctionInfo({ id: this.itemModal.auctionId });
        this.$emit("setExpandedData", { id: this.itemModal.auctionId, data });
        this.deleteLot();
      }
    },
    closeModalOrders() {
      this.flagModalOrders = false;
      this.urlOrders = null;
    },
    selectAllConvertAuction({ items }) {
      if (this.selected.length) {
        this.selected = [];
      } else {
        let itemsFiltered = items.filter(
          (x) => !!x.MaxBidder && x.MaxBidder !== null
          // && x.docNo === null
        );
        this.selected = itemsFiltered;
      }
    },
    selectSingleonvertAuction(isSelected, item) {
      const { auctionItemID } = item;
      if (!isSelected) {
        this.selected.push(item);
      } else {
        this.selected = this.selected.filter((x) => x.auctionItemID !== auctionItemID);
      }
    },
    closeModalContainers() {
      this.containers = null;
      this.flagModalContainers = false;
    },
    openModalContainer(containers) {
      this.containers = containers;
      this.flagModalContainers = true;
    },
  },
  destroyed() {
    clearInterval(this.Timer);
    this.Timer = null;
  },
};
</script>

<style lang="scss" scoped>
.gap {
  gap: 2px;
}
</style>
