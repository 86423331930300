<template>
  <div class="elevation-5" style="height: calc(100% - 28px)">
    <v-row class="pa-2">
      <v-col cols="12">
        <v-row>
          <v-col class="text-end py-2">
            <v-btn
              color="primary px-12"
              small
              light
              @click="exportCustomerData"
              :disabled="!items.length"
            >
              Export
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            :cols="!!infoWinner.auctionItemID.length ? 6 : 12"
            class="px-5"
          >
            <v-data-table
              dense
              expand-icon="mdi-file-tree"
              light
              hide-default-footer
              class="elevation-5 rounded-0 pa-2 ma-0 tabWinners"
              :headers="tableHeader"
              :items="items"
              height="calc( 70vh - 44px)"
              :items-per-page="-1"
            >
              <template v-slot:item="{ item }">
                <tr
                  :style="{
                    fontWeight: 'bolder',
                    backgroundColor:
                      item.auctionItemID == infoWinner.auctionItemID
                        ? '#3284f4 !important'
                        : 'white !important',
                    color:
                      item.auctionItemID == infoWinner.auctionItemID
                        ? 'white'
                        : '',
                  }"
                >
                  <td v-for="{ value } in header" :key="value">
                    <v-row class="flex-nowrap" v-if="value === 'actions'">
                      <v-col class="pa-0 ma-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              class="lighten-2 rounded-0"
                              block
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="setInfoWinner(item)"
                            >
                              <v-icon small dark>mdi-account-edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Set Winner</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <span v-else>{{ item[value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6" v-if="!!infoWinner.auctionItemID.length">
            <v-row v-if="loadingCustomers">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-row>
            <v-card light v-else
              ><v-card-text>
                <v-row class="pa-2">
                  <v-autocomplete
                    v-model="infoWinner.CustomerId"
                    :items="loserCustomers"
                    color="white"
                    dense
                    solo
                    light
                    hide-details
                    class="lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24 rounded-md"
                    item-text="nameBid"
                    item-value="organization_id"
                    :menu-props="{ light: true }"
                    return-object
                  >
                  </v-autocomplete>
                </v-row>
                <v-row class="px-2">
                  <v-btn
                    color="primary"
                    class="lighten-2 rounded-md"
                    @click="sendNewWinner"
                    light
                    width="50%"
                    :disabled="!infoWinner.CustomerId.toString().length"
                  >
                    Set Winner
                  </v-btn>
                </v-row>
              </v-card-text></v-card
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AlertTabWinner
      :show="showAlert"
      @closeAlert="closeAlert"
      :info="infoWinner"
    />
  </div>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { serverToLocalTime } from "@/helpers/mocks/dates.js";
import { mapActions, mapGetters, mapState } from "vuex";
import { exportTemplate } from "@/utils/ExportExcel.js";

import AlertTabWinner from "./AlertTabWinner.vue";
export default {
  props: [],
  components: { AlertTabWinner },
  async created() {
    this.items = await this.getWinners({ auctionid: this.detailsItemId.id });
  },
  computed: {
    ...mapState({
      detailsItemId: (state) => state.moduleAuctions.detailsItemId,
      customerBids: (state) => state.moduleAuctions.customerBids,
    }),
    tableHeader() {
      return generateHeaderData(this.header);
    },
    loserCustomers() {
      // return "";
      const dataFiltered = this.customerBids[
        this.infoWinner.auctionItemID
      ].filter((x) => this.infoWinner.CustomerIdItem !== x.id);

      return dataFiltered.map((x) => {
        return {
          nameBid: `${x.organization_descr} - Max Bid: ${x.maxBid}`,
          ...x,
        };
      });
    },
  },
  data() {
    return {
      items: [],
      infoWinner: {
        auctionId: "",
        auctionItemID: "",
        CustomerId: "",
      },
      header: [
        {
          text: "Lot Id",
          value: "auctionItemID",
        },
        {
          text: "Lot Name",
          value: "name",
        },
        {
          text: "Customer",
          value: "organization_descr",
        },
        {
          text: "Winning Bid",
          value: "MaxBid",
        },
        {
          text: "Depot",
          value: "Location",
        },
        {
          text: "Size",
          value: "CntrSize",
        },
        {
          text: "Type",
          value: "CntrType",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      loadingCustomers: false,
      showAlert: false,
    };
  },
  methods: {
    serverToLocalTime,
    ...mapActions({
      getWinners: "moduleAuctions/getWinners",
      getCustomers: "moduleAuctions/getCustomersBids",
    }),
    exportCustomerData() {
      let name = "A_Winners_";
      name += this.detailsItemId.name
        .split(" ")
        .map((x) => x[0])
        .join("_");

      exportTemplate(
        this.items,
        [
          {
            text: "Lot Id",
            value: "auctionItemID",
          },
          {
            text: "Name",
            value: "name",
          },
          {
            text: "Customer",
            value: "organization_descr",
          },
          {
            text: "Winning Bid",
            value: "MaxBid",
          },
          {
            text: "Depot",
            value: "Location",
          },
          {
            text: "Size",
            value: "CntrSize",
          },
          {
            text: "Type",
            value: "CntrType",
          },
        ],
        name,
        7,
      );
    },
    async setInfoWinner(item) {
      this.loadingCustomers = true;
      if (!this.customerBids[item.auctionItemID])
        await this.getCustomers({
          auctionId: item.auctionId,
          auctionItemID: item.auctionItemID,
        });

      this.infoWinner = {
        auctionId: item.auctionId.toString(),
        auctionItemID: item.auctionItemID.toString(),
        CustomerIdItem: item.CustomerId,
        CustomerId: "",
      };
      this.loadingCustomers = false;
    },
    async sendNewWinner() {
      this.showAlert = true;
    },
    async closeAlert(flag) {
      this.showAlert = false;
      if (flag) {
        const res = await Promise.all([
          this.getCustomers({
            auctionId: this.infoWinner.auctionId,
            auctionItemID: this.infoWinner.auctionItemID,
          }),
          this.getWinners({
            auctionid: this.detailsItemId.id,
          }),
        ]);
        this.items = res[1];

        const winner = this.items.filter(
          (x) => x.auctionItemID == this.infoWinner.auctionItemID,
        );

        console.log(winner);
        this.infoWinner = {
          auctionId: winner[0].auctionId,
          auctionItemID: winner[0].auctionItemID,
          CustomerId: winner[0].CustomerId,
        };
      }
    },
  },
};
</script>

<style lang="scss">
.tabWinners {
  thead {
    th:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    th:nth-child(8) {
      border-radius: 0px 10px 10px 0px;
    }
  }
  tr {
    /* background: var(--v-primary-lighten3) !important; */
    td:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    td:nth-child(8) {
      border-radius: 0px 10px 10px 0px;
    }
  }
}
</style>
