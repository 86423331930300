<template>
  <modal width="50%" :show="show" :title="title" background="white">
    <template v-slot>
      <v-row
        v-for="{ label, value } of fieldsForm"
        :key="value"
        class="text-h6 py-2 white"
      >
        <v-col
          cols="4"
          class="px-5 font-weight-bold text-capitalize d-flex align-center primary--text"
        >
          <span class="text-primary"> {{ label }}: </span>
        </v-col>
        <v-col cols="8" class="px-5 d-flex align-center">
          <v-textarea
            name="input-5-4"
            background-color="white"
            solo
            light
            v-model="obj[value]"
          ></v-textarea>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-row
        class="ma-0 pa-0"
        style="background-color: white"
        justify="space-around"
      >
        <v-col class="ma-1 pa-0" cols="5">
          <v-btn
            class="elevation-5"
            color="primary"
            outlined
            @click="updateComments"
            block
            light
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="ma-1 pa-0" cols="5">
          <v-btn
            class="elevation-5"
            color="primary"
            style="border: none"
            text
            light
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
  },

  props: ["show", "title", "item"],
  created() {
    this.obj.comments = this.item.comments;
  },
  data() {
    return {
      obj: {},
      fieldsForm: [{ label: "comments", value: "comments" }],
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      updateAuction: "moduleAuctions/updateAuction",
    }),
    closeModal() {
      this.$emit("showModal");
    },
    updateComments() {
      this.$emit("updateComments", this.obj);
    },
  },
};
</script>
