<template>
  <div>
    <v-data-table
      dense
      expand-icon="mdi-file-tree"
      item-key="id"
      light
      hide-default-footer
      show-expand
      class="elevation-5 rounded-0 pa-0 ma-0 overflow-y"
      height="75vh"
      fixed-header
      :headers="header"
      :items="items"
      :items-per-page="-1"
      @item-expanded="itemExpanded"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom offset-x dense>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense class="white pa-0 a">
            <v-list-item
              v-for="{ name, method } in menuData(item)"
              :key="method"
              style="width: 100%"
              :style="{
                border:
                  method === 'cancelAuction' ? ' 1px solid red' : ' 1px solid #3284f4',
              }"
              tag="button"
              @click="callMehotd(item, method)"
            >
              <v-list-item-title
                class="text-capitalize text-overline"
                :class="[method === 'cancelAuction' ? 'red--text' : 'primary--text']"
                >{{ name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.timeLeft`]="{ item }">
        <span
          v-if="item.timeLeft !== 'Finished'"
          style="opacity: 1"
          class="text-subtitle-2"
          >{{ item.timeLeft }}</span
        >

        <span
          v-if="item.timeLeft == 'Finished'"
          style="opacity: 0.5"
          class="text-subtitle-2"
          >{{ item.timeLeft }}</span
        >
      </template>

      <template v-slot:[`item.Status`]="{ item }">
        <v-chip
          :color="
            item.Status === 'Active'
              ? item.auctionFinished
                ? '#ffd600'
                : 'green'
              : item.Status === 'Expired'
              ? '#ffd600'
              : 'red'
          "
          text-color="white"
          dense
        >
          {{ item.Status === "Active" && item.auctionFinished ? "Expired" : item.Status }}
        </v-chip>
      </template>

      <template v-slot:footer="{}">
        <Pagination
          :totalPages="listAuctions.pages"
          :totalItems="listAuctions.count"
          :optionsTable="optionsPagination"
          :tabSelected="tabSelected"
          @updateItemsPerPage="updateItemsPerPage"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 20px !important"
          class="elevation-0"
        >
          <TableLineItems
            :data="dataExpanded[item.id]"
            :EndDateTime="item.EndDateTime"
            :StartDate="item.StartDateTime"
            :optionsPagination="optionsPagination"
            :status="item.Status"
            :auctionFinished="item.auctionFinished"
            @setExpandedData="setExpandedData"
          />
        </td>
      </template>
    </v-data-table>
    <ModalDetailsAuctions
      v-if="showModalDetailAuctions"
      :show="showModalDetailAuctions"
      @showModal="showModalDetailsAuction"
      title="Details"
    />
    <ModalUpdateDetails
      v-if="showModalUpdateAuctionsFlag"
      :show="showModalUpdateAuctionsFlag"
      :item="updateDetailItem"
      :tabSelected="tabSelected"
      @showModal="updateAuction"
      title="Update Details"
    />
    <ConfirmModal
      v-if="confirmCancelAuctionFlag"
      :show="confirmCancelAuctionFlag"
      @closeConfirmCancelAuction="confirmCancelAuctionFlag = false"
      @removeAuction="removeAuction"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Pagination from "@/components/Auctions/Pagination";
import TableLineItems from "@/components/Auctions/TableLineItems.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { fromISOToUtc } from "@/helpers/mocks/dates.js";
import { DateTime } from "luxon";
import ModalDetailsAuctions from "@/components/Auctions/ModalDetailsAuctions.vue";
import ModalUpdateDetails from "@/components/Auctions/ModalUpdateDetails";
import ConfirmModal from "@/layout/ConfirmModal.vue";
export default {
  props: ["optionsPagination", "headerTable", "items", "tabSelected"],
  components: {
    Pagination,
    TableLineItems,
    ModalDetailsAuctions,
    ModalUpdateDetails,
    ConfirmModal,
  },

  computed: {
    ...mapState({
      listAuctions: (state) => state.moduleAuctions.listAuctions,
    }),
    header() {
      return generateHeaderData(this.headerTable);
    },
  },
  data() {
    return {
      DateTime,
      dataExpanded: {},
      showModalDetailAuctions: false,
      showModalUpdateAuctionsFlag: false,
      updateDetailItem: null,
      confirmCancelAuctionFlag: false,
      idAuction: "",
      auctionName: "",
    };
  },
  methods: {
    fromISOToUtc,
    ...mapMutations({
      setDetailsItemId: "moduleAuctions/setDetailsItemId",
    }),
    ...mapActions({
      getAuctionInfo: "moduleAuctions/getAuctionInfo",
      getBids: "moduleAuctions/getBids",
      deleteAuction: "moduleAuctions/deleteAuction",
    }),

    async itemExpanded({ item, value }) {
      if (value && !this.dataExpanded.hasOwnProperty(item.id)) {
        let data = await this.getAuctionInfo({ id: item.id });

        this.$set(this.dataExpanded, item.id, data);
      }
    },
    callMehotd(item, name) {
      const payload = name === "expireAuction" ? { ...item, action: "expired" } : item;
      this[name](payload);
    },
    converToAndOrder(value) {
      console.log("converToAndOrder");
      console.log(value);
    },
    cancelAuction({ id, name }) {
      this.confirmCancelAuctionFlag = true;
      this.idAuction = id;
      this.auctionName = name;
      // this.deleteAuction({ id, auctionName: name, status: this.tabSelected });
    },
    removeAuction() {
      this.deleteAuction({
        id: this.idAuction,
        auctionName: this.auctionName,
        status: this.tabSelected,
      });
      this.idAuction = "";
      this.auctionName = "";
      this.confirmCancelAuctionFlag = false;
    },
    expireAuction({ id, name, action }) {
      this.deleteAuction({
        id,
        auctionName: name,
        action,
        status: this.tabSelected,
      });
    },
    menuData(item) {
      switch (item.Status) {
        case "Active":
          return [
            { name: "Details", method: "showModalDetailsAuction" },
            { name: "Update Auction", method: "updateAuction" },
            { name: "Add Lots", method: "addContainers" },
            { name: "cancel auction", method: "cancelAuction" },
            { name: "Expire auction", method: "expireAuction" },
          ];
        case "Expired":
          return [{ name: "Details", method: "showModalDetailsAuction" }];
        case "Inactive":
          return [{ name: "Details", method: "showModalDetailsAuction" }];
      }
    },
    setExpandedData({ id, data }) {
      this.$set(this.dataExpanded, id, data);
    },
    showModalDetailsAuction(item) {
      if (this.showModalDetailAuctions) {
        this.setDetailsItemId(null);
      } else {
        this.setDetailsItemId(item);
      }
      this.showModalDetailAuctions = !this.showModalDetailAuctions;
    },
    updateAuction(item) {
      this.updateDetailItem = this.updateDetailItem ? null : item;

      this.showModalUpdateAuctionsFlag = !this.showModalUpdateAuctionsFlag;
    },

    updateItemsPerPage(value) {
      this.$emit("updateItemsPerPage", value);
    },
    addContainers(item) {
      this.$router.push({
        path: "/auctions/create-auction",
        query: {
          id: item.id,
        },
        // params: {
        //   id: item.id,
        // },
      });
    },
  },
};
</script>

<style lang="scss">
.a {
  button:hover {
    background: var(--v-primary-lighten2);
    div {
      color: white !important;
    }
  }
}
</style>
