<template>
  <div class="my-5 mx-5 elevation-5" style="height: calc(100% - 28px)">
    <v-row class="pa-2 justify-end" style="gap: 2px">
      <v-col cols="8" class="text-end py-2">
        <v-row class="justify-end">
          <v-col cols="10">
            <v-autocomplete
              :items="customersFiltered"
              v-model="customersData"
              @keydown.enter="addUnregisteredCustomer"
              color="white"
              dense
              solo
              clearable
              light
              :menu-props="{ light: true }"
              hide-details
              class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center elevation-24"
              item-text="name_email"
              item-value="primary_email"
              :search-input.sync="autocompleteSearch"
              multiple
              return-object
            >
              <template v-slot:selection="{ item }">
                <v-chip color="primary" small close
                  >{{ item.organization_descr || item.primary_email }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              class="rounded-l-0"
              style="height: 40px"
              @click="saveCustomers"
            >
              Add New Customer
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="text-end py-2">
        <v-btn
          color="primary px-12"
          style="height: 40px"
          light
          @click="exportCustomerData"
          :disabled="!items.length"
        >
          Export
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="height: calc(100% - 68px)">
      <v-col cols="12" class="fill-height px-5">
        <v-data-table
          dense
          expand-icon="mdi-file-tree"
          light
          fixed-header
          hide-default-footer
          class="elevation-5 rounded-0 pa-2 ma-0 tabCustomer"
          :headers="tableHeader"
          :items="items"
          :items-per-page="-1"
          height="calc( 70vh - 68px)"
        >
          <template v-slot:[`item.Status`]="{ item }">
            <v-chip
              :color="item.Status === 'Y' ? 'green' : 'red'"
              text-color="white"
              dense
            >
              {{ `${item.Status === "Y" ? "Active" : "Inactive"}` }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { serverToLocalTime } from "@/helpers/mocks/dates.js";
import { mapActions, mapGetters, mapState } from "vuex";
import { exportTemplate } from "@/utils/ExportExcel.js";
export default {
  async created() {
    const res = await Promise.all([
      this.getCustomers({ auctionId: this.detailsItemId.id }),
      this.getsOrganizations(),
    ]);
    this.items = res[0];
    this.customers = this.filteredListCustomers;
    // console.log("items", this.items);
  },
  computed: {
    ...mapState({
      detailsItemId: (state) => state.moduleAuctions.detailsItemId,
    }),
    ...mapGetters({
      filteredListCustomers: "moduleOrganizations/filteredListCustomers",
    }),
    // customers() {
    //   return this.filteredListCustomers.map((x) => {
    //     return { ...x, name_email: `${x.primary_email} | ${x.organization_descr}` };
    //   });
    // },
    customersFiltered() {
      const customers = this.items.map((x) => x.customerEmail);
      const customerList = this.customers.map((x) => {
        return {
          ...x,
          name_email: `${x.primary_email} | ${x.organization_descr}`,
        };
      });
      return customerList.filter((x) => {
        return !customers.includes(x.primary_email);
      });
    },
    tableHeader() {
      const header = [
        {
          text: "Organization Name",
          value: "OrganizationName",
        },
        {
          text: "Organization Email",
          value: "customerEmail",
        },
        {
          text: "Contact Name",
          value: "contactName",
        },
        {
          text: "Invited By",
          value: "InvitedBy",
        },
        {
          text: "Status",
          value: "Status",
        },
      ];
      return generateHeaderData(header);
    },
    itemsDataParse() {
      return this.items.map((x) => {
        return { ...x, Status: x.Status === "Y" ? "Active" : "Inactive" };
      });
    },
  },
  data() {
    return {
      items: [],
      autocompleteSearch: "",
      customersData: [],

      customers: [],
    };
  },
  methods: {
    serverToLocalTime,
    ...mapActions({
      getCustomers: "moduleAuctions/getCustomers",
      addCustomer: "moduleAuctions/addCustomer",
      getsOrganizations: "moduleOrganizations/getsOrganizations",
    }),
    exportCustomerData() {
      let name = "A_Winners_";
      name += this.detailsItemId.name
        .split(" ")
        .map((x) => x[0])
        .join("_");
      exportTemplate(
        this.itemsDataParse,
        [
          {
            text: "Organization Name",
            value: "OrganizationName",
          },

          {
            text: "Organization Email",
            value: "customerEmail",
          },

          {
            text: "Contact Name",
            value: "contactName",
          },
          {
            text: "Invited By",
            value: "InvitedBy",
          },
          {
            text: "Status",
            value: "Status",
          },
        ],
        name,
        4,
      );
    },
    async saveCustomers() {
      const obj = {
        auctionId: this.detailsItemId.id,
        customers: this.customersData.map((x) => {
          return {
            customerEntityId: x.organization_id,
            primary_email: x.primary_email,
          };
        }),
      };

      await this.addCustomer(obj);
      this.items = await this.getCustomers({
        auctionId: this.detailsItemId.id,
      });
      this.autocompleteSearch = "";
      this.customersData = [];
    },

    addUnregisteredCustomer() {
      this.customersData.push({
        primary_email: this.autocompleteSearch,
        organization_descr: this.autocompleteSearch,
      });
      this.customers.push({
        primary_email: this.autocompleteSearch,
        organization_descr: this.autocompleteSearch,
      });

      this.autocompleteSearch = "";
    },
  },
};
</script>

<style lang="scss">
.tabCustomer {
  thead {
    th:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    th:last-child {
      border-radius: 0px 10px 10px 0px;
    }
  }
  tr {
    background: var(--v-primary-lighten3) !important;
    td:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    td:last-child {
      border-radius: 0px 10px 10px 0px;
    }
  }
}
</style>
