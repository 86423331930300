<template>
  <v-dialog v-model="show" persistent max-width="max-content" light>
    <v-card>
      <v-card-title class="text-h5">
        You sure you want to set Winner?
      </v-card-title>
      <v-card-text>
        <v-list-item style="min-height: auto">
          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h6">
              <span class="font-weight-bold"> Auction Name:</span>
              {{ detailsItemId.name }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item style="min-height: auto">
          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h6">
              <span class="font-weight-bold">Lot Id</span>
              {{ info.auctionItemID }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item style="min-height: auto">
          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h6">
              <span class="font-weight-bold">Customer Name</span>
              {{ info.CustomerId.organization_descr }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="setWinner"> Yes </v-btn>
        <v-btn color="red darken-1" text @click="$emit('closeAlert')">
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["show", "info"],
  computed: {
    ...mapState({
      detailsItemId: (state) => state.moduleAuctions.detailsItemId,
    }),
  },
  methods: {
    ...mapActions({
      setManualWinner: "moduleAuctions/setManualWinner",
    }),
    async setWinner() {
      let res = await this.setManualWinner({
        auctionId: this.info.auctionId,
        auctionItemID: this.info.auctionItemID,
        CustomerId: this.info.CustomerId.id,
      });

      this.$emit("closeAlert", !!res);
    },
  },
};
</script>

<style></style>
