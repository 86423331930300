<template>
  <Card title="time left to end" maxWidth="none" style="width: fit-content">
    <template #content>
      <div class="d-flex justify-center">
        <div v-if="days" class="countdown__block">
          <div class="countdown__digit">{{ twoDigits(days) }}</div>
          <div class="countdown__text">Days</div>
        </div>
        <div class="countdown__block">
          <div class="countdown__digit">{{ twoDigits(hours) }}</div>
          <div class="countdown__text">Hrs</div>
        </div>
        <div class="countdown__block">
          <div class="countdown__digit">{{ twoDigits(minutes) }}</div>
          <div class="countdown__text">Min</div>
        </div>
        <div class="countdown__block">
          <div class="countdown__digit">{{ twoDigits(seconds) }}</div>
          <div class="countdown__text">Sec</div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/Auctions/Card.vue";
import { fromISOToUtc } from "@/helpers/mocks/dates.js";
export default {
  props: ["EndDateTime", "expired"],
  components: {
    Card,
  },
  computed: {
    secondCount() {
      return this.calculatedDate - this.now;
    },
    calculatedDate() {
      return Math.trunc(Date.parse(this.EndDateTime)) / 1000;
    },
    seconds() {
      if (this.secondCount < 0) return 0;

      return this.secondCount % 60;
    },
    minutes() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60) % 60;
    },
    hours() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60 / 60) % 24;
    },
    days() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60 / 60 / 24);
    },
  },
  created() {
    this.timer = window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
      if (
        !this.expired &&
        !this.finish &&
        this.calculatedDate - this.now <= 0
      ) {
        this.finish = true;
        this.$emit("onFinish");
        clearInterval(this.timer);
      }
    }, 1000);
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      finish: false,
      timer: null,
    };
  },
  methods: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  &__block {
    text-align: center;
    padding: 0px 15px;
    position: relative;
    &:first-child {
      padding-left: 0;
      .countdown__digit {
        &:before {
          display: none;
        }
      }
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &__text {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 300;
  }
  &__digit {
    font-size: 2.125rem !important;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 5px;
    &:before {
      content: ":";
      position: absolute;
      left: -5px;
    }
  }
}
</style>
