<template>
  <modal width="80%" :show="show" :title="title" background="white">
    <template v-slot>
      <v-card class="pa-0 flex-column d-flex pa-5" style="height: 70vh" light>
        <v-row class="fill-height">
          <v-col cols="12" class="fill-height">
            <DetailsPreviewAuction :items="objPreview" />
          </v-col>
        </v-row>
      </v-card>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0" style="background-color: white">
        <v-col class="pa-2" cols="6">
          <v-btn class="elevation-5" color="primary" block @click="send">
            {{ `${!flagAuctionUpdate ? "Create Auction" : "Update Auction"}` }}
          </v-btn>
        </v-col>
        <v-col class="pa-2" cols="6">
          <v-btn
            class="elevation-5 primary--text"
            color="transparent"
            block
            @click="$emit('changeFlagModalPreviewAuction', false)"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import DetailsPreviewAuction from "@/components/Auctions/DetailsPreviewAuction.vue";
import MasterContractPreviewAuction from "@/components/Auctions/MasterContractPreviewAuction.vue";
import CreateAuctionPreview from "@/components/Auctions/CreateAuctionPreview.vue";

export default {
  components: {
    Modal,
    DetailsPreviewAuction,
    MasterContractPreviewAuction,
    CreateAuctionPreview,
  },
  props: ["show", "title", "objPreview", "flagAuctionUpdate"],

  data() {
    return {
      step: 0,
      masterContractFlag: false,
      steppers: [
        {
          index: 0,
          title: "Details",
          component: DetailsPreviewAuction,
          props: { items: this.objPreview },
        },
        {
          index: 1,
          title: "Master Contract",
          component: MasterContractPreviewAuction,
          on: {
            changeMasterContractFlag: this.changeMasterContractFlag,
          },
        },
        {
          index: 2,
          title: "Accept",
          component: CreateAuctionPreview,
          props: { auctionName: this.objPreview.name },
          on: {
            createAuction: this.createAuction,
          },
        },
      ],
    };
  },
  methods: {
    send() {
      !this.flagAuctionUpdate ? this.$emit("createAuction") : this.$emit("updateAuction");
    },
    nextStep() {
      this.step += 1;
    },
    backStep() {
      this.step -= 1;
    },
    changeMasterContractFlag(value) {
      this.masterContractFlag = value;
    },
  },
};
</script>

<style></style>
