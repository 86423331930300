<template>
  <v-row style="gap: 5px">
    <v-col v-for="{ title, value } in auctionsStatus" :key="value">
      <v-btn
        block
        light
        class="primary--text auction-tab elevation-10"
        :class="[value === tabSelected ? 'active' : '']"
        @click="setTab(value)"
      >
        {{ title }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["tabSelected"],
  data() {
    return {
      //   tabSelected: "A",
      auctionsStatus: [
        { title: "Active", value: "A" },
        { title: "Expired", value: "E" },
        { title: "Inactive", value: "C" },
      ],
    };
  },
  methods: {
    setTab(value) {
      //   this.tabSelected = value;
      this.$emit("setTab", value);
    },
  },
};
</script>

<style scoped lang="scss">
.auction-tab {
  &:hover {
    background-color: $primary;
    color: white !important;
  }
  &.active {
    background-color: $primary !important;
    color: white !important;
  }
}
</style>
