<template>
  <v-row style="max-height: 300px" class="overflow-y-auto">
    <v-col>
      <v-data-table
        dense
        expand-icon="mdi-file-tree"
        item-key="date_customer"
        light
        hide-default-footer
        height="100%"
        class="elevation-5 rounded-0 px-2 ma-0 x"
        :items-per-page="-1"
        :headers="tableHeader"
        :items="itemsValues"
        :loading="historyCustomerTableLoadingFlag"
      >
        <template v-slot:[`item.date_created`]="{ item }">
          <span>{{ serverToLocalTime(item.date_created) }}</span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { serverToLocalTime } from "@/helpers/mocks/dates.js";
export default {
  props: ["header", "items", "historyCustomerTableLoadingFlag"],
  computed: {
    tableHeader() {
      return generateHeaderData(this.header);
    },
    itemsValues() {
      if (this.items) {
        return this.items.map((x) => {
          return { ...x, date_customer: `${x.date_created}_${x.organization_descr}` };
        });
      }

      return [];
    },
  },
  methods: {
    serverToLocalTime,
  },
};
</script>

<style lang="scss">
.x {
  thead {
    th:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    th:nth-child(3) {
      border-radius: 0px 10px 10px 0px;
    }
  }
  tr {
    background: var(--v-primary-lighten3) !important;
    td:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    td:nth-child(3) {
      border-radius: 0px 10px 10px 0px;
    }
  }
}
</style>
