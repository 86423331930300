var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-5 rounded-0 pa-0 ma-0 overflow-y",attrs:{"dense":"","expand-icon":"mdi-file-tree","item-key":"id","light":"","hide-default-footer":"","show-expand":"","height":"75vh","fixed-header":"","headers":_vm.header,"items":_vm.items,"items-per-page":-1},on:{"item-expanded":_vm.itemExpanded},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","offset-x":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"white pa-0 a",attrs:{"dense":""}},_vm._l((_vm.menuData(item)),function({ name, method }){return _c('v-list-item',{key:method,staticStyle:{"width":"100%"},style:({
              border:
                method === 'cancelAuction' ? ' 1px solid red' : ' 1px solid #3284f4',
            }),attrs:{"tag":"button"},on:{"click":function($event){return _vm.callMehotd(item, method)}}},[_c('v-list-item-title',{staticClass:"text-capitalize text-overline",class:[method === 'cancelAuction' ? 'red--text' : 'primary--text']},[_vm._v(_vm._s(name))])],1)}),1)],1)]}},{key:`item.timeLeft`,fn:function({ item }){return [(item.timeLeft !== 'Finished')?_c('span',{staticClass:"text-subtitle-2",staticStyle:{"opacity":"1"}},[_vm._v(_vm._s(item.timeLeft))]):_vm._e(),(item.timeLeft == 'Finished')?_c('span',{staticClass:"text-subtitle-2",staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(item.timeLeft))]):_vm._e()]}},{key:`item.Status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.Status === 'Active'
            ? item.auctionFinished
              ? '#ffd600'
              : 'green'
            : item.Status === 'Expired'
            ? '#ffd600'
            : 'red',"text-color":"white","dense":""}},[_vm._v(" "+_vm._s(item.Status === "Active" && item.auctionFinished ? "Expired" : item.Status)+" ")])]}},{key:"footer",fn:function({}){return [_c('Pagination',{attrs:{"totalPages":_vm.listAuctions.pages,"totalItems":_vm.listAuctions.count,"optionsTable":_vm.optionsPagination,"tabSelected":_vm.tabSelected},on:{"updateItemsPerPage":_vm.updateItemsPerPage}})]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"elevation-0",staticStyle:{"padding":"20px !important"},attrs:{"colspan":headers.length}},[_c('TableLineItems',{attrs:{"data":_vm.dataExpanded[item.id],"EndDateTime":item.EndDateTime,"StartDate":item.StartDateTime,"optionsPagination":_vm.optionsPagination,"status":item.Status,"auctionFinished":item.auctionFinished},on:{"setExpandedData":_vm.setExpandedData}})],1)]}}],null,true)}),(_vm.showModalDetailAuctions)?_c('ModalDetailsAuctions',{attrs:{"show":_vm.showModalDetailAuctions,"title":"Details"},on:{"showModal":_vm.showModalDetailsAuction}}):_vm._e(),(_vm.showModalUpdateAuctionsFlag)?_c('ModalUpdateDetails',{attrs:{"show":_vm.showModalUpdateAuctionsFlag,"item":_vm.updateDetailItem,"tabSelected":_vm.tabSelected,"title":"Update Details"},on:{"showModal":_vm.updateAuction}}):_vm._e(),(_vm.confirmCancelAuctionFlag)?_c('ConfirmModal',{attrs:{"show":_vm.confirmCancelAuctionFlag},on:{"closeConfirmCancelAuction":function($event){_vm.confirmCancelAuctionFlag = false},"removeAuction":_vm.removeAuction}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }