<template>
  <div class="my-5 mx-5 elevation-5" style="height: calc(100% - 28px)">
    <v-row class="pa-5 fill-height" style="background-color: white">
      <v-col cols="12">
        <Tabs
          :tab="tab"
          :tabValues="tabValues"
          @changeTab="changeTab"
          :classes="`elevation-0 gap `"
          :btnclasses="'primary lighten-3 rounded-t-lg rounded-b-0 font-weight-light black--text'"
          :classActive="'primary lighten-1 black--text'"
        />

        <v-row style="height: calc(100% - 28px)">
          <v-col cols="12">
            <v-row>
              <v-col class="text-end py-2">
                <v-btn
                  color="primary px-12"
                  small
                  @click="exportDataTable"
                  :disabled="exportButtonDisable"
                  light
                >
                  Export
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="height: calc(100% - 44px)">
              <v-col cols="12" class="fill-height">
                <v-data-table
                  v-if="tab === 0"
                  dense
                  expand-icon="mdi-file-tree"
                  light
                  hide-default-footer
                  class="elevation-5 rounded-0 pa-2 ma-0 tabTable"
                  :headers="lotHeader"
                  :items="itemsLots"
                  height="calc( 70vh - 100px)"
                  :items-per-page="-1"
                  fixed-header
                >
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item.name || "-" }}
                  </template>
                  <template v-slot:[`item.MaxAmount`]="{ item }">
                    {{ item.MaxAmount || "-" }}
                  </template>
                  <template v-slot:[`item.MaxBidder`]="{ item }">
                    {{ item.MaxBidder || "-" }}
                  </template>
                </v-data-table>
                <v-data-table
                  v-else
                  dense
                  expand-icon="mdi-file-tree"
                  light
                  hide-default-footer
                  class="elevation-5 rounded-0 pa-2 ma-0 tableBid"
                  :headers="bidsHeader"
                  :items="bidHistoryData"
                  height="calc( 70vh - 100px)"
                  :items-per-page="-1"
                  fixed-header
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { serverToLocalTime } from "@/helpers/mocks/dates.js";
import { mapActions, mapState } from "vuex";
import Tabs from "@/components/General/Tabs.vue";
import { exportTemplate } from "@/utils/ExportExcel.js";
export default {
  components: {
    Tabs,
  },
  props: [],
  async created() {
    this.itemsLots = await this.getAuctionInfo({ id: this.detailsItemId.id });
    this.itemsBid = await this.getBidsOverview({ id: this.detailsItemId.id });
  },
  computed: {
    ...mapState({
      detailsItemId: (state) => state.moduleAuctions.detailsItemId,
    }),
    lotHeader() {
      let tabHeader = [
        {
          text: "Lot Id",
          value: "auctionItemID",
        },
        {
          text: "Lot Name",
          value: "name",
        },
        {
          text: "Leading Bid",
          value: "MaxAmount",
        },
        {
          text: "Leading Bidder",
          value: "MaxBidder",
        },
        {
          text: "Depot",
          value: "Location",
        },
        {
          text: "Size",
          value: "CntrSize",
        },
        {
          text: "Type",
          value: "CntrType",
        },
      ];
      return generateHeaderData(tabHeader);
    },
    bidsHeader() {
      let tabHeader = [
        {
          text: "Lot Id",
          value: "auctionItemID",
        },
        {
          text: "Lot name",
          value: "name",
        },
        {
          text: "Customer",
          value: "CustomerName",
        },
        {
          text: "Price",
          value: "amount",
        },
        {
          text: "Submission Time",
          value: "SubmittedTime",
        },
      ];
      return generateHeaderData(tabHeader);
    },
    exportButtonDisable() {
      return this.tab === 0 ? !this.itemsLots.length : !this.itemsBid.length;
    },
    bidHistoryData() {
      return this.itemsBid.map((x) => {
        return {
          ...x,
          SubmittedTime: this.serverToLocalTime(x.SubmittedTime),
        };
      });
    },
  },
  data() {
    return {
      itemsLots: [],
      itemsBid: [],
      tab: 0,
      tabValues: [
        {
          title: "Lots",
        },
        {
          title: "Bid History",
        },
      ],
    };
  },
  methods: {
    serverToLocalTime,
    ...mapActions({
      getCustomers: "moduleAuctions/getCustomers",
      getAuctionInfo: "moduleAuctions/getAuctionInfo",
      getBidsOverview: "moduleAuctions/getBidsOverview",
    }),
    changeTab(value) {
      this.tab = value;
    },
    exportDataTable() {
      const items = this.tab === 0 ? this.itemsLots : this.bidHistoryData;
      const header =
        this.tab === 0
          ? [
              {
                text: "Lot Id",
                value: "auctionItemID",
              },
              {
                text: "Lot Name",
                value: "name",
              },
              {
                text: "Leading Bid",
                value: "MaxAmount",
              },
              {
                text: "Leading Bidder",
                value: "MaxBidder",
              },
              {
                text: "Depot",
                value: "Location",
              },
              {
                text: "Size",
                value: "CntrSize",
              },
              {
                text: "Type",
                value: "CntrType",
              },
            ]
          : [
              {
                text: "Lot id",
                value: "auctionItemID",
              },
              {
                text: "Lot name",
                value: "name",
              },
              {
                text: "Customer",
                value: "CustomerName",
              },
              {
                text: "Price",
                value: "amount",
              },
              {
                text: "Submission Time",
                value: "SubmittedTime",
              },
            ];
      const name = this.tab === 0 ? `overview_lots` : "overview_bids";

      exportTemplate(items, header, name, header.length);
    },
  },
};
</script>

<style lang="scss">
.tableBid {
  thead {
    th:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    th:nth-child(5) {
      border-radius: 0px 10px 10px 0px;
    }
  }
  tr {
    background: var(--v-primary-lighten3) !important;
    td:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    td:nth-child(5) {
      border-radius: 0px 10px 10px 0px;
    }
  }
}
.tabTable {
  thead {
    th:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    th:nth-child(7) {
      border-radius: 0px 10px 10px 0px;
    }
  }
  tr {
    background: var(--v-primary-lighten3) !important;
    td:nth-child(1) {
      border-radius: 10px 0px 0px 10px;
    }
    td:nth-child(7) {
      border-radius: 0px 10px 10px 0px;
    }
  }
}
</style>
