<template>
  <modal width="80%" :show="show" :title="title" background="white">
    <template v-slot>
      <v-row
        class="pa-5"
        style="background-color: white; height: calc(70vh + 100px)"
      >
        <v-col cols="12" class="fill-height">
          <Tabs
            :tab="tab"
            :tabValues="tabValues"
            @changeTab="changeTab"
            :classes="`elevation-0 gap `"
            :btnclasses="'primary lighten-3 rounded-t-lg rounded-b-0 font-weight-light black--text'"
            :classActive="'primary lighten-1 black--text'"
          />
          <component :is="tabComponent" />
          <!-- "header", "items", "itemKey" -->
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0" style="background-color: white">
        <v-col class="ma-1 pa-0" cols="12">
          <v-btn
            class="elevation-5"
            color="primary"
            style="border: none"
            text
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import Tabs from "@/components/General/Tabs.vue";
import TabCustomer from "@/components/Auctions/TabCustomer.vue";
import TabBids from "@/components/Auctions/TabBids.vue";
import TabOverview from "@/components/Auctions/TabOverview.vue";
import TabWinners from "@/components/Auctions/TabWinners.vue";
export default {
  components: {
    Modal,
    Tabs,
    TabCustomer,
    TabBids,
    TabOverview,
    TabWinners,
  },
  props: ["show", "title"],
  data() {
    return {
      tab: 0,
      tabValues: [
        {
          title: "Overview",
        },
        {
          title: "Customers",
        },
        {
          title: "Bids Overview",
        },
        {
          title: "Winners",
        },
      ],
    };
  },
  computed: {
    tabComponent() {
      switch (this.tab) {
        case 0:
          return TabOverview;
          break;
        case 1:
          return TabCustomer;
          break;
        case 2:
          return TabBids;
          break;
        case 3:
          return TabWinners;
          break;
      }
    },
  },
  watch: {
    itemData() {
      this.validate = false;
    },
  },
  methods: {
    changeTab(value) {
      this.tab = value;
    },

    closeModal() {
      this.$emit("showModal");
    },
  },
};
</script>

<style scoped>
button {
  border: thin #20212e solid;
}
</style>
