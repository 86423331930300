<template>
  <ValidationObserver v-slot="{ invalid }" tag="div" class="white">
    <modal width="50%" :show="show" :title="title" background="white">
      <template v-slot>
        <v-row
          v-for="{ label, value } of fieldsForm"
          :key="value"
          class="text-h6 py-2 white"
        >
          <v-col
            cols="4"
            class="px-5 font-weight-bold text-capitalize d-flex align-center primary--text"
          >
            <span class="text-primary"> {{ label }}: </span>
          </v-col>
          <v-col cols="8" class="px-5 d-flex align-center">
            <span v-if="value === 'end_day' || value === 'start_day'" style="width: 100%">
              <DatePicker
                v-model="obj[value]"
                :data="obj"
                :keyData="value"
                :solo="false"
                :flat="false"
                title=" "
                hideDetails="true"
                toFormat="yyyy-LL-dd"
                textfield_classes="rounded-5 "
                :light="true"
                backgroundColor="white"
                :rules="`required|${value === 'start_day' ? startDayRule : ''}`"
              >
                <template v-slot:error="{ errors, failedRules }">
                  <span
                    v-if="errors.length"
                    class="red--text text-caption text-capitalize"
                  >
                    {{
                      value === "start_day"
                        ? `Auction Start Day ${
                            failedRules.hasOwnProperty("required")
                              ? errors[0]
                              : "must be greater than current time"
                          }`
                        : `Auction End Day  ${errors[0]}`
                    }}</span
                  >
                </template>
              </DatePicker>
            </span>
            <span
              v-else-if="value === 'time_end_day' || value === 'time_start_day'"
              style="width: 100%"
            >
              <v-menu
                ref="menu"
                v-model="obj[`menu_${value}`]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="obj[value]"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    hide-details
                    type="text"
                    dense
                    outlined
                    light
                    clearable
                    single-line
                    class="ma-0 rounded-5 text-field__calendar"
                    hint="YYYY-MM-DD format"
                    persistent-hint
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="obj[`menu_${value}`]"
                  v-model="obj[value]"
                  full-width
                  scrollable
                ></v-time-picker>
              </v-menu>
            </span>
            <ValidationProvider
              v-else
              v-slot="{ errors }"
              rules="required"
              name="auction name"
              style="width: 100%"
            >
              <v-text-field
                class="ma-0 rounded-5"
                hide-details
                type="text"
                dense
                outlined
                light
                clearable
                single-line
                v-model="obj[value]"
              >
              </v-text-field>
              <span
                v-if="errors.length"
                class="red--text text-caption text-capitalize text-start"
              >
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <span class="text-center primary--text white d-block">
          Time Zone: {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
        </span>
      </template>

      <template v-slot:actions>
        <v-row class="ma-0 pa-0" style="background-color: white" justify="space-around">
          <v-col class="ma-1 pa-0" cols="5">
            <v-btn
              class="elevation-5"
              color="primary"
              outlined
              @click="updateDetails"
              block
              light
              :disabled="invalid"
            >
              Save
            </v-btn>
          </v-col>
          <v-col class="ma-1 pa-0" cols="5">
            <v-btn
              class="elevation-5"
              color="primary"
              style="border: none"
              text
              light
              @click="closeModal"
              block
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </modal>
  </ValidationObserver>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  FromFormatToTimeStamp,
  fromISOToUtc,
  FromFormatToTimeStampServer,
  localToServerTime,
  parseFromFormat,
} from "@/helpers/mocks/dates.js";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
  },

  props: ["show", "title", "item", "tabSelected"],
  created() {
    let startTime = parseFromFormat(this.item.StartDateTime, "yyyy-LL-dd HH:mm:ss");
    let endTime = parseFromFormat(this.item.EndDateTime, "yyyy-LL-dd HH:mm:ss");
    this.obj.name = this.item.name;
    this.obj.start_day = startTime.toISODate();

    this.obj.time_start_day = startTime.toISOTime({
      includeOffset: false,
      suppressSeconds: true,
    });

    this.obj.end_day = endTime.toISODate();

    this.obj.time_end_day = endTime.toISOTime({
      includeOffset: false,
      suppressSeconds: true,
    });
  },
  data() {
    return {
      obj: {},
      fieldsForm: [
        { label: "name", value: "name" },
        { label: "start day", value: "start_day" },
        { label: "start time", value: "time_start_day" },
        { label: "End day", value: "end_day" },
        { label: "End time", value: "time_end_day" },
      ],
    };
  },
  computed: {
    startDayRule() {
      return `min_date:${Math.trunc(Date.parse(fromISOToUtc(this.obj.start_day)))}`;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      updateAuction: "moduleAuctions/updateAuction",
    }),
    closeModal() {
      this.$emit("showModal");
    },
    async updateDetails() {
      const StartDateTime = FromFormatToTimeStamp(
        `${this.obj.start_day} ${this.obj.time_start_day}`,
        "yyyy-LL-dd HH:mm"
      );
      const StartDateServerTime = localToServerTime(
        `${this.obj.start_day} ${this.obj.time_start_day}`
      );
      const EndDateTime = FromFormatToTimeStamp(
        `${this.obj.end_day} ${this.obj.time_end_day}`,
        "yyyy-LL-dd HH:mm"
      );
      const EndDateTimeServerTimer = localToServerTime(
        `${this.obj.end_day} ${this.obj.time_end_day}`
      );

      const res = await this.updateAuction({
        auctionId: this.item.id,
        data: {
          name: this.obj.name,
          StartDateTime,
          EndDateTime,
          StartDateServerTime,
          EndDateTimeServerTimer,
        },
        status: this.tabSelected,
      });

      if (res) this.closeModal();
    },
  },
};
</script>
