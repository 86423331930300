<template>
  <div class="row">
    <div class="col">
      <AuctionTable
        :errors="errors"
        :selectedProp="selectedProp"
        @updateSelected="({ item, value }) => $emit('updateSelected', { item, value })"
      />
    </div>
    <div class="col">
      <CreateAuctionForm
        :errors="errors"
        :tableSelected="tableSelected"
        :objAuctionForm="objAuctionForm"
        :containersNo="containersNo"
        @updateSelected="({ item, value }) => $emit('updateSelected', { item, value })"
        @addNewLot="$emit('addNewLot')"
      />
    </div>
  </div>
</template>

<script>
import CreateAuctionForm from "@/components/InventoryQtySumm/CreateAuctionForm.vue";
import AuctionTable from "@/components/InventoryQtySumm/AuctionTable.vue";

export default {
  props: ["selectedProp", "tableSelected", "objAuctionForm", "containersNo", "errors"],
  components: {
    CreateAuctionForm,
    AuctionTable,
  },
};
</script>

<style lang="scss" scoped></style>
