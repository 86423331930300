<template>
  <v-data-table
    :headers="headerData"
    :items="buyerData"
    dense
    height="50vh"
    hide-default-footer
    disable-pagination
    class="elevation-5 ma-0 pa-4 rounded-0 main-table"
  >
    <template v-slot:item="{ item }">
      <tr>
        <td
          v-for="(header, key) in headerData"
          :key="key"
          class="text-center pa-0 dark-blue"
        >
          <v-btn
            color="#20212E"
            class="lighten-2 rounded-0"
            block
            v-if="header.value === 'Action'"
            :disabled="item.external == 'N'"
            @click="showModal(item, 'Update Buyer Party')"
          >
            <span>Edit<v-icon dark>mdi-account-edit</v-icon></span>
          </v-btn>
          <span v-else-if="header.value === 'external'">
            <v-icon v-if="item.external == 'Y'" color="green"
              >mdi-checkbox-marked</v-icon
            ><v-icon v-else color="red">mdi-minus-box</v-icon></span
          >
          <v-switch
            v-else-if="header.value === 'enabled'"
            dense
            hide-details
            :input-value="item[header.value] == 'Y'"
            @change="changeEnableValue(item)"
            :disabled="item.external == 'N'"
            class="ma-auto pa-0 switch-center"
          ></v-switch>
          <v-btn
            icon
            color="#20212E"
            v-else-if="header.value === 'contactInfo'"
            @click="showModal(item, 'Contact Info', 1)"
          >
            <v-icon>mdi-clipboard-text-search</v-icon>
          </v-btn>
          <span v-else>{{ item[header.value] }} </span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { header, buyerData } from "@/helpers/mocks/buyers.js";
import { get_orgs, update_toggle_buyer_party } from "@/helpers/api/indexv2.js";
import { mapMutations } from "vuex";
export default {
  props: ["orgs"],
  data() {},
  computed: {
    headerData() {
      let headerData = [];

      for (let { value, title } of this.header) {
        // console.log(value);
        headerData.push({
          text: title,
          align: "center",
          sortable: false,
          value,
          class: "table-header text-capitalize primary",
        });
      }
      return headerData;
    },
  },
  watch: {
    orgs() {
      this.buyerData = [];
      this.orgs.map((org) => {
        this.buyerData = [...this.buyerData, Object.assign({}, org)];
        console.log("orgs");
      });
    },
  },
  data() {
    return { header, buyerData: [], switch1: true };
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    async updateItem(itemData) {
      console.log(itemData);
      this.$store.commit("setSpinnerShow", true);
      let response;
      try {
        response = await update_toggle_buyer_party({
          id: itemData.id,
        });
      } catch (error) {
        console.log(error);
      }

      if (response && response.pass) {
        try {
          let orgs = await get_orgs();
          this.$store.commit("setOrganizations", orgs.data);
          this.setSnackbar({
            text: response.message,
            status: "success",
            show: "showNav",
          });
          console.log("in updateDataItem try");
        } catch (error) {
          console.log(error);
        }
      } else {
        this.setSnackbar({
          text: response.msg,
          status: "danger",
          show: "showNav",
        });
      }
      this.$store.commit("setSpinnerShow", false);
    },
    changeEnableValue(item) {
      // item["enabled"] = item["enabled"] === "Y" ? "N" : "Y";
      this.updateItem(item);
      // console.log(item);
    },
    showModal(item, title, paperRow) {
      console.log(item);
      this.$emit("showModal", { item, title, paperRow });
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.buyer-table {
  .switch-center {
    .v-input--selection-controls__input {
      margin: auto;
    }
  }
}
</style>
