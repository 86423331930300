<template>
  <v-col cols="12" class="pa-0 ma-0">
    <v-container class="max-width d-flex align-center pa-0 px-2" fluid>
      <v-row class="pa-2 ma-0 justify-space-between">
        <v-col cols="3"></v-col>
        <v-col cols="6" class="d-flex align-center justify-center pa-0 mx-auto">
          <v-pagination
            :value="page"
            :length="totalPages"
            :total-visible="7"
            @input="setPage"
            color="primary"
          ></v-pagination>
        </v-col>
        <v-col cols="3" class="d-flex align-center pa-0 ma-0">
          <v-row class="pa-0 ma-0 align-center">
            <v-col class="pa-0 ma-0 align-center d-flex" cols="8">
              <v-row class="pa-0 ma-0">
                <v-col
                  cols="7"
                  class="pa-0 ma-0 align-center justify-end d-flex text-lg-overline"
                  style="font-size: xx-small !important"
                >
                  <span> Rows per page: </span>
                </v-col>
                <v-col cols="5" class="pa-0 ma-0">
                  <v-select
                    :items="numberPerPageOptions"
                    @change="updateItemsPerPage"
                    :value="optionsTable.itemsPerPage"
                    dense
                    solo
                    hide-details
                    class="lighten-2 pa-0 pl-1 rounded-3 ma-0 text-center text-lg-caption"
                    :append-icon="''"
                    style="width: fit-content"
                    :menu-props="{ light: true }"
                    light
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="pa-0 ma-0 align-center text-right text-lg-overline"
              cols="4"
              style="font-size: xx-small !important"
            >
              <span v-if="totalItems"
                >{{
                  optionsTable.itemsPerPage !== "ALL"
                    ? optionsTable.itemsPerPage * (page - 1)
                    : 0
                }}
                -
                {{
                  optionsTable.itemsPerPage !== "ALL"
                    ? optionsTable.itemsPerPage * page > totalItems
                      ? totalItems
                      : optionsTable.itemsPerPage * page
                    : totalItems
                }}
                of {{ totalItems }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: ["totalPages", "totalItems", "optionsTable", "tabSelected"],
  data() {
    return {
      numberPerPageOptions: [20, 50, 100, 150, 200, 250, "ALL"],
    };
  },
  computed: {
    ...mapState({
      page: (state) => state.moduleAuctions.page,
    }),
  },
  methods: {
    ...mapActions({
      getAuctions: "moduleAuctions/getAuctions",
    }),
    ...mapMutations({
      setPageStore: "moduleAuctions/setPage",
    }),
    setPage(value) {
      this.setPageStore(value);
      this.getAuctions({
        offset: this.page - 1,
        LIMIT:
          value === "ALL" ? this.totalItems : this.optionsTable.itemsPerPage,
        status: this.tabSelected,
      });
    },
    updateItemsPerPage(value) {
      this.getAuctions({
        offset: this.page - 1,
        LIMIT: value === "ALL" ? this.totalItems : value,
        status: this.tabSelected,
      });
      this.$emit("updateItemsPerPage", value);
    },
  },
};
</script>

<style lang="scss">
.table-pagination {
  & .v-pagination__navigation {
    background: #20212e !important;
  }

  /* For page buttons except the active one */
  & .v-pagination__item:not(.v-pagination__item--active) {
    background: #20212e !important;
  }

  & button {
    font-size: small;
  }

  @media only screen and (min-width: 1904px) {
    & li {
      font-size: medium;
    }
  }
}
</style>
