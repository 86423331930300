<template>
  <modal width="20%" :show="show" :title="title">
    <template v-slot>
      <v-card class="pa-0 flex-column d-flex pa-5 rounded-0" flat>
        <v-row justify="center" align="center">
          <v-col cols="auto">
            <span class="text-subtitle-2 font-wight-bold"
              >Are you sure you want to remove this container slot?</span
            >
          </v-col>
        </v-row>
      </v-card>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0">
        <v-col class="px-2" cols="6">
          <v-btn
            class="elevation-5"
            color="primary"
            block
            @click="$emit('deleteEoo')"
          >
            Yes
          </v-btn>
        </v-col>
        <v-col class="px-2" cols="6">
          <v-btn
            class="elevation-5"
            color="error"
            block
            @click="$emit('closeConfimationEOO')"
          >
            No
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import DetailsPreviewAuction from "@/components/Auctions/DetailsPreviewAuction.vue";
import MasterContractPreviewAuction from "@/components/Auctions/MasterContractPreviewAuction.vue";
import CreateAuctionPreview from "@/components/Auctions/CreateAuctionPreview.vue";

export default {
  components: {
    Modal,
    DetailsPreviewAuction,
    MasterContractPreviewAuction,
    CreateAuctionPreview,
  },
  props: ["show", "title"],

  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
