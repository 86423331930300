<template>
  <v-row class="fill-height justify-center align-content-center">
    <v-col>
      <div
        class="d-flex flex-column elevation-5 pa-5 mx-auto"
        style="width: fit-content"
      >
        <span class="text-overline" style="font-size: 16px"
          >Create auction {{ auctionName }}?</span
        >

        <v-row class="fill-height">
          <v-col cols="6" class="pa-2">
            <v-btn
              color="primary lighten-2"
              block
              class="fit"
              @click="$emit('createAuction')"
            >
              Yes
            </v-btn>
          </v-col>
          <v-col cols="6" class="pa-2">
            <v-btn color="red darken-2" block class="fill-height"> No </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["auctionName"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
