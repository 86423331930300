<template>
  <modal width="80%" :show="show" :title="title" background="white">
    <template v-slot>
      <v-card class="pa-0 flex-column d-flex pa-5" style="height: 100%" light>
        <v-row class="fill-height">
          <v-col cols="12" class="fill-height">
            <v-textarea outlined v-model="jsonData" rows="50" />
          </v-col>
        </v-row>
      </v-card>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0" style="background-color: white">
        <v-col class="pa-2" cols="6">
          <v-btn
            class="elevation-5"
            color="primary"
            block
            @click="$emit('loadLots', jsonData)"
          >
            save lots
          </v-btn>
        </v-col>
        <v-col class="pa-2" cols="6">
          <v-btn
            class="elevation-5 primary--text"
            color="transparent"
            block
            @click="$emit('changeFlagLoadLots', false)"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: ["show", "title", "objPreview"],

  data() {
    return {
      step: 0,
      jsonData: null,
    };
  },
  methods: {},
};
</script>

<style></style>
