<template>
  <modal width="50%" :show="show" :title="title" background="white">
    <template>
      <v-container class="ma-0 pa-0"
        style="background-color: white;"
        justify="center">
        <v-card-text class="display-1 align-center black--text" style="text-align: center;">
          Are you sure you want to remove lot #{{ item.auctionItemID }}?
        </v-card-text>
      </v-container>
    </template>

    <template v-slot:actions>
      <v-row
        class="ma-0 pa-0"
        style="background-color: white"
        justify="space-around"
      >
        <v-col class="ma-1 pa-0" cols="5">
          <v-btn
            color="white"
            style="background-color: green;"
            outlined
            @click="deleteLot"
            block
            light
          >
            Confirm
          </v-btn>
        </v-col>
        <v-col class="ma-1 pa-0" cols="5">
          <v-btn
            class="elevation-5"
            color="primary"
            style="border: none"
            text
            light
            @click="closeModal"
            block
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
  },

  props: ["show", "title", "item"],
  data() {
    return {
      obj: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      deleteLot: "moduleAuctions/deleteLot",
    }),
    closeModal() {
      this.$emit("showModal");
    },
    deleteLot() {
      this.$emit("deleteLot", this.obj);
    },
  },
};
</script>
