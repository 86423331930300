<template>
  <modal width="50%" :show="show" :title="title" background="white">
    <template v-slot>
      <v-row class="white">
        <v-col cols="12" class="pa-5">
          <v-data-table
            dense
            light
            hide-default-footer
            class="elevation-5 rounded-0 pa-0 ma-0"
            height="50vh"
            :items-per-page="-1"
            :headers="header"
            :items="items"
          >
            <template v-slot:[`item.url`]="{ item }">
              <router-link :to="`orders/${item.docNo}`">
                {{ `${location}/orders/${item.docNo}` }}
              </router-link>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0" style="background-color: white" justify="space-around">
        <v-col class="ma-1 pa-0" cols="12">
          <v-btn
            class="elevation-5"
            color="primary"
            style="border: none"
            text
            light
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions } from "vuex";
const HEADER = [
  {
    text: "Order #",
    value: "docNo",
  },
  {
    text: "URL",
    value: "url",
  },
  {
    text: "Customer Name",
    value: "customerName",
  },
];
export default {
  components: {
    Modal,
  },

  props: ["show", "title", "items"],

  data() {
    return {};
  },
  computed: {
    header() {
      return generateHeaderData(HEADER);
    },
    location() {
      return window.location.origin;
    },
  },
  watch: {},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
