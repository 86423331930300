<template>
  <v-card elevation="2" :max-width="maxWidth || '200px'" class="ma-auto" light>
    <v-row class="flex-column py-2 px-4 flex-wrap" dense>
      <v-col class="py-2">
        <span class="text-h6 font-weight-medium text-capitalize">{{
          title
        }}</span>
      </v-col>
      <v-col class="px-1 pb-2">
        <span
          class="text-h4 font-weight-bold text-capitalize text-center d-flex justify-center items-center"
        >
          <div class="px-2">
            <v-icon
              :color="colorIcon || 'white'"
              class="rounded-xl pa-1 ma-auto d-block"
              :class="bgIcon || 'primary'"
              style="line-height: initial"
              v-if="icon"
              :size="sizeIcon || 'large'"
              >{{ icon }}
            </v-icon>
          </div>
          <slot name="content">
            <span class="text-black text-h4 text-weight-bolder text-capitalize">
              {{ content }}</span
            >
          </slot>
        </span>
      </v-col>
      <v-col>
        <span class="text-subtitle-2 font-weight-light text-capitalize">{{
          subtitle
        }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: [
    "title",
    "content",
    "subtitle",
    "icon",
    "maxWidth",
    "colorIcon",
    "bgIcon",
    "sizeIcon",
  ],
};
</script>

<style></style>
