<template>
  <v-form ref="buyerForm" v-model="formValid">
    <v-row class="ma-0 pa-0" v-for="(DataRow, index) in paperRows" :key="index">
      <v-col class="ma-0 pa-2 pt-3 pb-0">
        <v-card
          class="ma-auto text-end rounded-0 elevation-5"
          outlined
          color="#20212E"
        >
          <div
            class="text-overline ma-0 pr-4"
            v-show="paperRows.length == totalOfPaperRowsDefault"
          >
            {{ DataRow.title }}
          </div>

          <v-row class="ma-0 pa-0 row-paper">
            <v-col
              class="ma-0 pa-0"
              v-for="(field, index) in DataRow.fields"
              :key="index"
            >
              <v-text-field
                class="text-center ma-1"
                hide-details
                type="text"
                v-model="itemData[field.value]"
                :rules="field.rule && rules[field.rule]"
                :disabled="paperRows.length != totalOfPaperRowsDefault"
                outlined
                dense
              >
                <template v-slot:label>
                  {{ field.title }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: ["validate", "itemData", "show", "paperRows"],
  data() {
    return {
      formValid: true,
      buyerPartyItem: {},
      totalOfPaperRowsDefault: 2,
      rules: {
        emptyRules: [v => !!v || "Name is required"],
        emailRules: [
          v => !!v || "Email is required",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
    };
  },
  watch: {
    show() {
      this.$refs.buyerForm.resetValidation();
    },
    validate() {
      console.log(this.validate);
      if (this.validate) {
        this.$refs.buyerForm.validate();
        this.$emit("sendForm", {
          valid: this.formValid,
          data: this.itemData,
        });
      }
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}
.buyer-form {
  background-color: #1e1e1e;
}

.row-paper {
  flex-direction: column;
}
</style>
